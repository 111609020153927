import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useToast } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';

interface DownloadPdfParams {
  accountId: string;
}

export const usePdfDownload = (): UseMutationResult<void, Error, DownloadPdfParams> => {
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const { showToast } = useToast();
  const sentry = useEmberService('sentry');

  return useMutation({
    mutationFn: async ({ accountId }: DownloadPdfParams) => {
      const response = await fetchApi(`v5/bank_accounts/${accountId}/account_certificate`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch PDF: ${response.statusText}`);
      }
    },
    retry: 15,
    retryDelay: 1000,
    onError: error => {
      sentry.captureException(error);
      showToast({
        text: formatMessage({ id: 'requests.mileage.steps.journey.errors.something-wrong' }),
        type: 'error',
      });
    },
    onSuccess: () => {
      showToast({
        text: formatMessage({ id: 'bank_accounts.certificate.toast-message' }),
        type: 'success',
      });
    },
  });
};
