import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type FlowHeaderProps } from '@repo/domain-kit/accounts-receivable';
import type { BasketItem } from '@repo/domain-kit/payment-links';
import type { Amount } from 'qonto/react/models/amount';
import type PaymentLinkMethodModel from 'qonto/models/payment-link-method';
import { PaymentLinkFormStep } from './payment-link-form-step';

export function Settings({
  breadcrumb,
  onClose,
  onContinue,
  selectedItems,
  selectedMethods,
  totalAmount,
  organizationName,
}: {
  breadcrumb: FlowHeaderProps['breadcrumb'];
  onClose: () => void;
  onContinue: () => void;
  selectedItems: BasketItem[];
  selectedMethods: PaymentLinkMethodModel['method'][];
  totalAmount: Amount<string>;
  organizationName: string;
}): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <PaymentLinkFormStep
      footerProps={{
        buttonLabel: formatMessage({ id: 'btn.confirm' }),
        onClickNext: () => {
          // TODO: Implement form submission
          onContinue();
        },
      }}
      headerProps={{
        breadcrumb,
        onClose,
        title: formatMessage({ id: 'payment-link.create.title' }),
      }}
      onClose={onClose}
      previewProps={{
        amount: totalAmount,
        items: selectedItems,
        methods: selectedMethods,
        organizationName,
      }}
    >
      <h2 className="title-3 mb-16" data-test-settings-title>
        <FormattedMessage id="payment-link.create.step-3.title" />
      </h2>
    </PaymentLinkFormStep>
  );
}
