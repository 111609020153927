import { useMutation, type UseMutateFunction } from '@tanstack/react-query';
import type { BasketItem } from '@repo/domain-kit/payment-links';
import { decamelize } from '@ember/string';
import type PaymentLinkModel from 'qonto/models/payment-link';
import { paymentLinkV2Namespace } from 'qonto/constants/hosts';
import { formatAmountToString } from 'qonto/utils/amount';
import type { PaymentLink } from 'qonto/react/api/models';
import type { Amount } from 'qonto/react/models/amount';
import transformKeys from 'qonto/utils/transform-keys';
import type { FetchFunction } from 'qonto/react/hooks/use-fetch-api';
import { useFetchApi } from './use-fetch-api';

interface RequestPayload {
  amount: Amount<string>;
  potentialPaymentMethods: PaymentLinkModel['potentialPaymentMethods'];
  settings: PaymentLinkModel['settings'];
  organizationName: string;
  items: BasketItem[];
}

export async function createPaymentLink(
  paymentLink: RequestPayload,
  fetchApi: FetchFunction
): Promise<PaymentLink> {
  const { settings, potentialPaymentMethods, items, organizationName, amount } = paymentLink;

  const response = await fetchApi(`${paymentLinkV2Namespace}/payment_links`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'payment_links',
        attributes: {
          amount: {
            currency: amount.currency,
            value: formatAmountToString({ amount: amount.value, precision: 2 }),
          },
          potential_payment_methods: potentialPaymentMethods,
          settings: {
            reusable: settings.reusable,
            ...(settings.redirectUrl ? { redirect_url: settings.redirectUrl } : {}),
          },
          organization_name: organizationName,
          items: items.map(item => transformKeys(item, decamelize)),
          payment_initiator: {
            resource_type: 'Basket',
          },
        },
      },
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to create payment link');
  }

  const json = (await response.json()) as { data: PaymentLink };
  return json.data;
}

export const useCreatePaymentLink = (): {
  mutate: UseMutateFunction<PaymentLink, Error, RequestPayload>;
  isPending: boolean;
  isError: boolean;
} => {
  const fetchApi = useFetchApi();

  const { isPending, isError, mutate } = useMutation({
    mutationFn: (paymentLink: RequestPayload) => createPaymentLink(paymentLink, fetchApi),
  });

  return {
    mutate,
    isPending,
    isError,
  };
};
